exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* overridden by src/lib/themes/guiHelpers.js */\n\n/* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */\n\n/* layout contants from `layout-constants.js` */\n\n.user-avatar_user-thumbnail_IwOnc {\n    width: 2rem;\n    height: 2rem;\n    border-radius: calc(0.5rem / 2);\n    vertical-align: middle;\n    box-shadow: 0 0 0 1px var(--ui-black-transparent);\n}\n", ""]);

// exports
exports.locals = {
	"user-thumbnail": "user-avatar_user-thumbnail_IwOnc",
	"userThumbnail": "user-avatar_user-thumbnail_IwOnc"
};