exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */\n\n/* layout contants from `layout-constants.js` */\n\n/* overridden by src/lib/themes/guiHelpers.js */\n\n.input_input-form_2EIqD {\n    height: 2rem;\n    padding: 0 0.75rem;\n\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    font-size: 0.625rem;\n    font-weight: bold;\n    color: var(--text-primary);\n    background-color: var(--input-background);\n\n    border-width: 1px;\n    border-style: solid;\n    border-color: var(--ui-black-transparent);\n    border-radius: 2rem;\n\n    outline: none;\n    cursor: text;\n    transition: 0.25s ease-out; /* @todo: standardize with var */\n    box-shadow: none;\n\n    /*\n        For truncating overflowing text gracefully\n        Min-width is for a bug: https://css-tricks.com/flexbox-truncated-text\n        @todo: move this out into a mixin or a helper component\n    */\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    min-width: 0;\n}\n\n.input_input-form_2EIqD:hover {\n    border-color: var(--looks-secondary);\n}\n\n.input_input-form_2EIqD:focus {\n    border-color: var(--looks-secondary);\n    box-shadow: 0 0 0 0.25rem var(--looks-transparent);\n}\n\n.input_input-small_1bkbs {\n    width: 3rem;\n    padding: 0 0.5rem;\n    text-overflow: clip;\n    text-align: center;\n}\n\n/* Chrome, Safari, Edge, Opera */\n\n.input_input-form_2EIqD::-webkit-outer-spin-button,\n.input_input-form_2EIqD::-webkit-inner-spin-button {\n    -webkit-appearance: none;\n    margin: 0;\n}\n\n/* Firefox */\n\n.input_input-form_2EIqD[type=number] {\n    -moz-appearance: textfield;\n}\n", ""]);

// exports
exports.locals = {
	"input-form": "input_input-form_2EIqD",
	"inputForm": "input_input-form_2EIqD",
	"input-small": "input_input-small_1bkbs",
	"inputSmall": "input_input-small_1bkbs"
};