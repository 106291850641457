exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* overridden by src/lib/themes/guiHelpers.js */\n\n.featured-projects_container_bte5t {\n    margin-bottom: 8px;\n}\n\n.featured-projects_opener-container_2VEwo {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n        -ms-user-select: none;\n            user-select: none;\n    opacity: 0.8;\n}\n\n.featured-projects_opener-container_2VEwo:hover {\n    opacity: 1;\n}\n\n.featured-projects_opener-content_2nRNs {\n    background-color: var(--ui-modal-background);\n    padding: 4px;\n    border: 1px solid var(--ui-black-transparent);\n    box-shadow: 0 0 8px 0 var(--shadow);\n}\n\n.featured-projects_projects_3USxm {\n    position: relative;\n    height: 155px;\n}\n\n.featured-projects_projects_3USxm.featured-projects_transition_2s3rd {\n    transition: .2s height;\n}\n\n.featured-projects_projects_3USxm.featured-projects_opened_1ALXH {\n    height: 310px;\n}\n\n.featured-projects_footer_UQGNp {\n    \n}\n", ""]);

// exports
exports.locals = {
	"container": "featured-projects_container_bte5t",
	"opener-container": "featured-projects_opener-container_2VEwo",
	"openerContainer": "featured-projects_opener-container_2VEwo",
	"opener-content": "featured-projects_opener-content_2nRNs",
	"openerContent": "featured-projects_opener-content_2nRNs",
	"projects": "featured-projects_projects_3USxm",
	"transition": "featured-projects_transition_2s3rd",
	"opened": "featured-projects_opened_1ALXH",
	"footer": "featured-projects_footer_UQGNp"
};