exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* overridden by src/lib/themes/guiHelpers.js */\n\n.data-url_code_fTTSN {\n    display: block;\n    width: 100%;\n    max-width: 100%;\n    min-width: 100%;\n    height: 5rem;\n    min-height: 3rem;\n    border: 1px solid var(--ui-black-transparent);\n    border-radius: 0.25rem;\n    padding: 0.25rem;\n    font-size: 0.875rem;\n    font-family: monospace;\n    margin: 0.5rem 0;\n}\n", ""]);

// exports
exports.locals = {
	"code": "data-url_code_fTTSN"
};