exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* overridden by src/lib/themes/guiHelpers.js */\n\n.load-extension_code_18JAZ,\n.load-extension_unsandboxed-container_3wUNS,\n.load-extension_unsandboxed-warning_3AXrs,\n.load-extension_sandboxed_2q4uS {\n    margin: 8px 0;\n}\n\n.load-extension_unsandboxed-container_3wUNS {\n    display: flex;\n    align-items: center;\n}\n\n.load-extension_unsandboxed-checkbox_3F7r1 {\n    margin-right: 8px;\n}\n\n.load-extension_unsandboxed-warning_3AXrs {\n    padding: 0.5rem;\n    border-radius: 0.25rem;\n    background-color: rgba(255, 81, 81, 0.25);\n    border: 1px solid red;\n}\n", ""]);

// exports
exports.locals = {
	"code": "load-extension_code_18JAZ",
	"unsandboxed-container": "load-extension_unsandboxed-container_3wUNS",
	"unsandboxedContainer": "load-extension_unsandboxed-container_3wUNS",
	"unsandboxed-warning": "load-extension_unsandboxed-warning_3AXrs",
	"unsandboxedWarning": "load-extension_unsandboxed-warning_3AXrs",
	"sandboxed": "load-extension_sandboxed_2q4uS",
	"unsandboxed-checkbox": "load-extension_unsandboxed-checkbox_3F7r1",
	"unsandboxedCheckbox": "load-extension_unsandboxed-checkbox_3F7r1"
};