exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* overridden by src/lib/themes/guiHelpers.js */\n\n.dial_container_1JhHX {\n    padding: 1rem;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n        -ms-user-select: none;\n            user-select: none;\n}\n\n.dial_dial-container_33mkQ {\n    position: relative;\n}\n\n.dial_dial-face_2aWlr, .dial_dial-handle_1OHhg, .dial_gauge_3bwaY {\n    position: absolute;\n    top: 0;\n    left: 0;\n    overflow: visible;\n}\n\n.dial_dial-face_2aWlr {\n    width: 100%;\n}\n\n.dial_dial-handle_1OHhg {\n    cursor: pointer;\n    width: 40px;\n    height: 40px;\n    /* Use margin to make positioning via top/left easier */\n    margin-left: calc(40px / -2);\n    margin-top: calc(40px / -2);\n}\n\n.dial_gauge-path_VQesE {\n    fill: var(--looks-transparent);\n    stroke: var(--looks-secondary);\n    stroke-width: 1px;\n}\n", ""]);

// exports
exports.locals = {
	"container": "dial_container_1JhHX",
	"dial-container": "dial_dial-container_33mkQ",
	"dialContainer": "dial_dial-container_33mkQ",
	"dial-face": "dial_dial-face_2aWlr",
	"dialFace": "dial_dial-face_2aWlr",
	"dial-handle": "dial_dial-handle_1OHhg",
	"dialHandle": "dial_dial-handle_1OHhg",
	"gauge": "dial_gauge_3bwaY",
	"gauge-path": "dial_gauge-path_VQesE",
	"gaugePath": "dial_gauge-path_VQesE"
};