exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* overridden by src/lib/themes/guiHelpers.js */\n\n.separator_separator_1jjpt {\n  width: 100%;\n  border: none;\n  border-top: 2px solid var(--ui-black-transparent);\n  margin: 0.5rem 0;\n}\n", ""]);

// exports
exports.locals = {
	"separator": "separator_separator_1jjpt"
};