exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".removed-trademarks_removed-trademarks_3XRwc {\n    opacity: 0.75;\n}\n", ""]);

// exports
exports.locals = {
	"removed-trademarks": "removed-trademarks_removed-trademarks_3XRwc",
	"removedTrademarks": "removed-trademarks_removed-trademarks_3XRwc"
};