exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* overridden by src/lib/themes/guiHelpers.js */\n\n/* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */\n\n/* layout contants from `layout-constants.js` */\n\n.slider-prompt_modal-content_m3ASz {\n    width: 360px;\n}\n\n.slider-prompt_body_fslhG {\n    background: var(--ui-modal-background);\n    padding: 1.5rem 2.25rem;\n}\n\n.slider-prompt_label_YNsvF {\n    font-weight: 500;\n    margin: 0 0 0.75rem;\n}\n\n.slider-prompt_min-input_2KCOC, .slider-prompt_max-input_2iIHC {\n    margin-bottom: 1.5rem;\n    width: 100%;\n    border: 1px solid var(--ui-black-transparent);\n    border-radius: 5px;\n    padding: 0 1rem;\n    height: 3rem;\n    color: var(--ui-text-primary-transparent);\n    font-size: .875rem;\n    background-color: var(--input-background);\n}\n\n.slider-prompt_button-row_-JDyB {\n    font-weight: bolder;\n    text-align: right;\n}\n\n.slider-prompt_button-row_-JDyB button {\n    padding: 0.75rem 1rem;\n    border-radius: 0.25rem;\n    background: white;\n    color: black;\n    border: 1px solid var(--ui-black-transparent);\n    font-weight: 600;\n    font-size: 0.85rem;\n}\n\n.slider-prompt_button-row_-JDyB button.slider-prompt_ok-button_1Ptk- {\n    background: var(--looks-secondary);\n    border: var(--looks-secondary);\n    color: white;\n}\n\n[dir=\"ltr\"] .slider-prompt_button-row_-JDyB button + button {\n    margin-left: 0.5rem;\n}\n\n[dir=\"rtl\"] .slider-prompt_button-row_-JDyB button + button {\n    margin-right: 0.5rem;\n}\n", ""]);

// exports
exports.locals = {
	"modal-content": "slider-prompt_modal-content_m3ASz",
	"modalContent": "slider-prompt_modal-content_m3ASz",
	"body": "slider-prompt_body_fslhG",
	"label": "slider-prompt_label_YNsvF",
	"min-input": "slider-prompt_min-input_2KCOC",
	"minInput": "slider-prompt_min-input_2KCOC",
	"max-input": "slider-prompt_max-input_2iIHC",
	"maxInput": "slider-prompt_max-input_2iIHC",
	"button-row": "slider-prompt_button-row_-JDyB",
	"buttonRow": "slider-prompt_button-row_-JDyB",
	"ok-button": "slider-prompt_ok-button_1Ptk-",
	"okButton": "slider-prompt_ok-button_1Ptk-"
};