exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".value-render_text_JOXgM {\r\n    margin: 0;\r\n    text-align: start;\r\n}\r\n\r\n.value-render_list_RYt2y {\r\n    display: flex;\r\n}\r\n\r\n.value-render_object-row_1Gi1m {\r\n    display: flex;\r\n}\r\n\r\n.value-render_key_2mCzZ {\r\n    color: rgb(97, 97, 97);\r\n}\r\n\r\n.value-render_list_RYt2y .value-render_key_2mCzZ {\r\n    margin: 0px 0.2rem;\r\n}", ""]);

// exports
exports.locals = {
	"text": "value-render_text_JOXgM",
	"list": "value-render_list_RYt2y",
	"object-row": "value-render_object-row_1Gi1m",
	"objectRow": "value-render_object-row_1Gi1m",
	"key": "value-render_key_2mCzZ"
};