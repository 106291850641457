exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* overridden by src/lib/themes/guiHelpers.js */\n\n.framerate-indicator_framerate-container_3JPah {\n    display: flex;\n    align-items: center;\n    padding: 0.25rem;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n        -ms-user-select: none;\n            user-select: none;\n}\n\n.framerate-indicator_framerate-label_39miY {\n    font-size: 0.625rem;\n    font-weight: bold;\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    color: var(--control-primary);\n    white-space: nowrap;\n}\n", ""]);

// exports
exports.locals = {
	"framerate-container": "framerate-indicator_framerate-container_3JPah",
	"framerateContainer": "framerate-indicator_framerate-container_3JPah",
	"framerate-label": "framerate-indicator_framerate-label_39miY",
	"framerateLabel": "framerate-indicator_framerate-label_39miY"
};