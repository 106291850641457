exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* overridden by src/lib/themes/guiHelpers.js */\n\n.stop-all_stop-all_15h2t {\n    width: 2rem;\n    height: 2rem;\n    padding: 0.375rem;\n    border-radius: 0.25rem;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n        -ms-user-select: none;\n            user-select: none;\n    cursor: pointer;\n}\n\n.stop-all_stop-all_15h2t:hover {\n    background-color: var(--looks-light-transparent);\n}\n\n.stop-all_stop-all_15h2t {\n    opacity: 0.5;\n}\n\n.stop-all_stop-all_15h2t.stop-all_is-active_PEgCP {\n    opacity: 1;\n}\n", ""]);

// exports
exports.locals = {
	"stop-all": "stop-all_stop-all_15h2t",
	"stopAll": "stop-all_stop-all_15h2t",
	"is-active": "stop-all_is-active_PEgCP",
	"isActive": "stop-all_is-active_PEgCP"
};