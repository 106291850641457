exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* overridden by src/lib/themes/guiHelpers.js */\n\n/* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */\n\n/* layout contants from `layout-constants.js` */\n\n/* wrapper to allow for touch slop if we decide to add it */\n\n.delete-button_delete-button_JjOIx {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n        -ms-user-select: none;\n            user-select: none;\n    cursor: pointer;\n    transition: all 0.15s ease-out;\n}\n\n.delete-button_delete-button-visible_mAZdj {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  overflow: hidden;  /* Mask the icon animation */\n  width: 1.75rem;\n  height: 1.75rem;\n  box-shadow: 0px 0px 0px 2px var(--looks-transparent);\n  background-color: var(--looks-secondary);\n  color: var(--ui-white);\n  border-radius: 50%;\n  font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n      -ms-user-select: none;\n          user-select: none;\n  cursor: pointer;\n  transition: all 0.15s ease-out;\n}\n\n.delete-button_delete-icon_2tDWR {\n    position: relative;\n    margin: 0.25rem;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n        -ms-user-select: none;\n            user-select: none;\n    transform-origin: 50%;\n}\n", ""]);

// exports
exports.locals = {
	"delete-button": "delete-button_delete-button_JjOIx",
	"deleteButton": "delete-button_delete-button_JjOIx",
	"delete-button-visible": "delete-button_delete-button-visible_mAZdj",
	"deleteButtonVisible": "delete-button_delete-button-visible_mAZdj",
	"delete-icon": "delete-button_delete-icon_2tDWR",
	"deleteIcon": "delete-button_delete-icon_2tDWR"
};