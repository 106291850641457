exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* overridden by src/lib/themes/guiHelpers.js */\n\n/* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */\n\n/* layout contants from `layout-constants.js` */\n\n.author-info_author-info__Auzh {\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    cursor: default;\n}\n\n.author-info_avatar_1EtPD {\n    margin-right: .5625rem;\n}\n\n.author-info_project-title_3abqH {\n    max-width: 12rem;\n    display: block;\n    overflow: hidden;\n    font-size: 0.875rem;\n    font-weight: bold;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    margin: 0;\n    padding: 0;\n}\n\n.author-info_username-line_30Olt {\n    max-width: 12rem;\n    font-size: 0.75rem;\n    display: block;\n    overflow: hidden;\n    font-weight: normal;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n}\n\n.author-info_username_3V5C- {\n    font-weight: bold;\n}\n\n.author-info_link_2G8-F {\n    text-decoration: none;\n    color: var(--menu-bar-foreground) !important;\n}\n\n.author-info_link_2G8-F:hover {\n    text-decoration: underline;\n}\n\n.author-info_link_2G8-F .author-info_author-info__Auzh {\n    cursor: pointer;\n}\n", ""]);

// exports
exports.locals = {
	"author-info": "author-info_author-info__Auzh",
	"authorInfo": "author-info_author-info__Auzh",
	"avatar": "author-info_avatar_1EtPD",
	"project-title": "author-info_project-title_3abqH",
	"projectTitle": "author-info_project-title_3abqH",
	"username-line": "author-info_username-line_30Olt",
	"usernameLine": "author-info_username-line_30Olt",
	"username": "author-info_username_3V5C-",
	"link": "author-info_link_2G8-F"
};