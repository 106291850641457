exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* overridden by src/lib/themes/guiHelpers.js */\n\n.unknown-platform-modal_modal-content_3mmhU {\n    max-width: 480px;\n    margin-top: 50px;\n}\n\n.unknown-platform-modal_body_3AaGs {\n    background: var(--ui-modal-background);\n    padding: 1.5rem 2.25rem;\n    max-height: calc(100vh - 150px);\n    overflow: auto;\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n}\n\n.unknown-platform-modal_details_qZ8jy {\n    font-family: monospace;\n    -webkit-user-select: text;\n       -moz-user-select: text;\n        -ms-user-select: text;\n            user-select: text;\n    text-wrap: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n\n.unknown-platform-modal_details_qZ8jy::before, .unknown-platform-modal_details_qZ8jy::after {\n    content: '\"';\n}\n\n.unknown-platform-modal_button_3kMtl {\n    font: inherit;\n    font-weight: bold;\n    padding: 0.75rem 1rem;\n    border-radius: 0.25rem;\n    border: 1px solid var(--ui-black-transparent);\n    background-color: var(--looks-secondary);\n    color: white;\n}\n\n.unknown-platform-modal_button_3kMtl:disabled {\n    opacity: 0.8;\n}\n", ""]);

// exports
exports.locals = {
	"modal-content": "unknown-platform-modal_modal-content_3mmhU",
	"modalContent": "unknown-platform-modal_modal-content_3mmhU",
	"body": "unknown-platform-modal_body_3AaGs",
	"details": "unknown-platform-modal_details_qZ8jy",
	"button": "unknown-platform-modal_button_3kMtl"
};