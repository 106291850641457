exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* overridden by src/lib/themes/guiHelpers.js */\n\n.share-button_share-button_36Wbh {\n    background: var(--data-primary);\n}\n\n.share-button_share-button-is-shared_2QzeZ {\n    background: var(--ui-black-transparent);\n    cursor: default;\n}\n", ""]);

// exports
exports.locals = {
	"share-button": "share-button_share-button_36Wbh",
	"shareButton": "share-button_share-button_36Wbh",
	"share-button-is-shared": "share-button_share-button-is-shared_2QzeZ",
	"shareButtonIsShared": "share-button_share-button-is-shared_2QzeZ"
};