exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* overridden by src/lib/themes/guiHelpers.js */\n\n.save-status_save-now_1Blzi {\n    cursor: pointer;\n    padding: 0 0.5rem;\n    height: 100%;\n    display: flex;\n    align-items: center;\n}\n\n.save-status_save-now_1Blzi:hover {\n    background-color: var(--ui-black-transparent);\n}\n", ""]);

// exports
exports.locals = {
	"save-now": "save-status_save-now_1Blzi",
	"saveNow": "save-status_save-now_1Blzi"
};