exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* overridden by src/lib/themes/guiHelpers.js */\n\n.component-attribute_card_11Z1w {\r\n    width: 100%;\r\n    min-height: 2rem;\r\n    border-radius: 0.25rem;\r\n    padding: 0.3rem;\r\n\r\n    background: var(--ui-white);\r\n    color: var(--text-primary);\r\n\r\n    border: 1px solid var(--ui-black-transparent);\r\n}\n\n.component-attribute_close_-2zU0 {\r\n    float: right;\r\n    width: 1.5rem;\r\n    height: 1.5rem;\r\n    font-size: 1rem;\r\n    margin-left: 0.5rem;\r\n\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n\r\n    padding: 0;\r\n    border: none;\r\n    background-color: #f0f0f0;\r\n\r\n    border-radius: 0.5rem;\r\n}\n\n.component-attribute_close_-2zU0 img {\r\n    height: 0.8rem;\r\n    width: 0.8rem;\r\n}\n\n.component-attribute_card_11Z1w p {\r\n    margin: 0;\r\n}\n\n.component-attribute_title_3yvud {\r\n    font-size: 1rem;\r\n}\n\n.component-attribute_col_1Q56R {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 0.5rem;\r\n    margin-top: 0.5rem;\r\n}", ""]);

// exports
exports.locals = {
	"card": "component-attribute_card_11Z1w",
	"close": "component-attribute_close_-2zU0",
	"title": "component-attribute_title_3yvud",
	"col": "component-attribute_col_1Q56R"
};