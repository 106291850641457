exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* overridden by src/lib/themes/guiHelpers.js */\n\n.community-button_community-button_20Q0O {\n    box-shadow: 0 0 0 1px var(--ui-black-transparent);\n}\n\n.community-button_community-button-icon_WReZ5 {\n    height: 1.25rem;\n}\n", ""]);

// exports
exports.locals = {
	"community-button": "community-button_community-button_20Q0O",
	"communityButton": "community-button_community-button_20Q0O",
	"community-button-icon": "community-button_community-button-icon_WReZ5",
	"communityButtonIcon": "community-button_community-button-icon_WReZ5"
};