exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* overridden by src/lib/themes/guiHelpers.js */\n\n.username-modal_modal-content_2AuAG {\n    width: 400px;\n}\n\n.username-modal_body_3Vuvu {\n    background: var(--ui-modal-background);\n    padding: 1.5rem 2.25rem;\n}\n\n.username-modal_body_3Vuvu input[type=\"checkbox\"],\n.username-modal_body_3Vuvu input[type=\"radio\"] {\n    margin: 3px;\n}\n\n.username-modal_text-input_1gGP7 {\n    margin-bottom: 1rem;\n    width: 100%;\n    border: 1px solid var(--ui-black-transparent);\n    border-radius: 5px;\n    padding: 0 1rem;\n    height: 3rem;\n    color: var(--ui-text-primary-transparent);\n    font-size: .875rem;\n    background-color: var(--input-background);\n}\n\n.username-modal_button-row_HCDS0 {\n    font-weight: bolder;\n    text-align: right;\n}\n\n.username-modal_button-row_HCDS0 button {\n    padding: 0.75rem 1rem;\n    border-radius: 0.25rem;\n    background: white;\n    border: 1px solid var(--ui-black-transparent);\n    font-weight: 600;\n    font-size: 0.85rem;\n}\n\n.username-modal_button-row_HCDS0 button[disabled] {\n    opacity: 0.5;\n}\n\n[dir=\"ltr\"] .username-modal_button-row_HCDS0 button + button {\n    margin-left: 0.5rem;\n}\n\n[dir=\"rtl\"] .username-modal_button-row_HCDS0 button + button {\n    margin-right: 0.5rem;\n}\n\n.username-modal_button-row_HCDS0 button.username-modal_ok-button_3Ig9c {\n    background: var(--looks-secondary);\n    border: var(--looks-secondary);\n    color: white;\n}\n\n.username-modal_button-row_HCDS0 button.username-modal_cancel-button_GTsd- {\n    color: black;\n}\n\n.username-modal_help-text_3Rb6d {\n    margin-bottom: 1rem;\n}\n\n.username-modal_must-change_3SKT6 {\n    font-weight: bold;\n}\n\n.username-modal_reset-link_37lrN {\n    cursor: pointer;\n}\n", ""]);

// exports
exports.locals = {
	"modal-content": "username-modal_modal-content_2AuAG",
	"modalContent": "username-modal_modal-content_2AuAG",
	"body": "username-modal_body_3Vuvu",
	"text-input": "username-modal_text-input_1gGP7",
	"textInput": "username-modal_text-input_1gGP7",
	"button-row": "username-modal_button-row_HCDS0",
	"buttonRow": "username-modal_button-row_HCDS0",
	"ok-button": "username-modal_ok-button_3Ig9c",
	"okButton": "username-modal_ok-button_3Ig9c",
	"cancel-button": "username-modal_cancel-button_GTsd-",
	"cancelButton": "username-modal_cancel-button_GTsd-",
	"help-text": "username-modal_help-text_3Rb6d",
	"helpText": "username-modal_help-text_3Rb6d",
	"must-change": "username-modal_must-change_3SKT6",
	"mustChange": "username-modal_must-change_3SKT6",
	"reset-link": "username-modal_reset-link_37lrN",
	"resetLink": "username-modal_reset-link_37lrN"
};