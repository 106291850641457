exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* overridden by src/lib/themes/guiHelpers.js */\n\n.cloud-variable-badge_badge_1n_s5 {\n    padding: 0.5rem;\n    margin: 5px 0 8px 0;\n    border: 1px solid var(--badge-border);\n    background-color: var(--badge-background);\n    display: flex;\n    flex-direction: column;\n    gap: 0.5rem;\n    border-radius: 0.5rem;\n}\n\n.cloud-variable-badge_title_4PmRN {\n    display: flex;\n    align-items: center;\n    gap: 0.5rem;\n    font-weight: bold;\n}\n\n.cloud-variable-badge_cloud-icon_H-B5L {\n    filter: var(--filter-icon-gray);\n}\n\n.cloud-variable-badge_servers_2foEa {\n    display: flex;\n    gap: 0.5rem;\n    align-items: center;\n}\n\n.cloud-variable-badge_server_PzfmO {\n    border: 1px solid var(--ui-black-transparent);\n    padding: 0.25rem 0.5rem;\n    margin: 0;\n    background: none;\n    border-radius: 1rem;\n}\n\n.cloud-variable-badge_server_PzfmO.cloud-variable-badge_selected_2WBlU {\n    background-color: var(--looks-secondary);\n    color: white;\n}\n", ""]);

// exports
exports.locals = {
	"badge": "cloud-variable-badge_badge_1n_s5",
	"title": "cloud-variable-badge_title_4PmRN",
	"cloud-icon": "cloud-variable-badge_cloud-icon_H-B5L",
	"cloudIcon": "cloud-variable-badge_cloud-icon_H-B5L",
	"servers": "cloud-variable-badge_servers_2foEa",
	"server": "cloud-variable-badge_server_PzfmO",
	"selected": "cloud-variable-badge_selected_2WBlU"
};