exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* overridden by src/lib/themes/guiHelpers.js */\n\n.add-component-btn_select_2taP_ {\r\n    width: 100%;\r\n    height: 2rem;\r\n    border-radius: calc(0.5rem / 2);\r\n    text-align: center;\r\n    border: none;\r\n    box-shadow: 0 0 0 1px var(--ui-black-transparent);\r\n    color: var(--menu-bar-foreground);\r\n    background-color:var(--looks-secondary);\r\n}\n\n.add-component-btn_select_2taP_ option {\r\n    background: var(--ui-secondary);\r\n    color: var(--text-primary);\r\n}", ""]);

// exports
exports.locals = {
	"select": "add-component-btn_select_2taP_"
};