exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* overridden by src/lib/themes/guiHelpers.js */\n\n.loupe_color-picker_1hE1x {\n    position: absolute;\n    top: 0;\n    left: 0;\n    border-radius: 100%;\n    border: 4px solid var(--ui-black-transparent);\n}\n", ""]);

// exports
exports.locals = {
	"color-picker": "loupe_color-picker_1hE1x",
	"colorPicker": "loupe_color-picker_1hE1x"
};