exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* overridden by src/lib/themes/guiHelpers.js */\n\n/* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */\n\n/* layout contants from `layout-constants.js` */\n\nbody {\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n}\n\nh2 {\n    font-size: 1.5rem;\n    font-weight: bold;\n}\n\np {\n    font-size: 1rem;\n    line-height: 1.5em;\n}\n\n.crash-message_crash-wrapper_15SLi {\n    background-color: var(--menu-bar-background);\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.crash-message_body_3OO__ {\n    width: 50%;\n    color: white;\n    text-align: center;\n}\n\n/* scratch-www was overriding the colors */\n\n.crash-message_body_3OO__ p, .crash-message_body_3OO__ h2 {\n    color: inherit;\n}\n\n.crash-message_reloadButton_35TdL {\n    border: 1px solid var(--motion-primary);\n    border-radius: 0.25rem;\n    padding: 0.5rem 2rem;\n    background: white;\n    color: var(--motion-primary);\n    font-weight: bold;\n    font-size: 0.875rem;\n    cursor: pointer;\n}\n\n.crash-message_header_CnM8i {\n    font-size: 1.5em;\n    font-weight: bold;\n}\n\n.crash-message_error-message_2oNef {\n    white-space: pre-wrap;\n    font-family: monospace;\n}\n", ""]);

// exports
exports.locals = {
	"crash-wrapper": "crash-message_crash-wrapper_15SLi",
	"crashWrapper": "crash-message_crash-wrapper_15SLi",
	"body": "crash-message_body_3OO__",
	"reloadButton": "crash-message_reloadButton_35TdL",
	"header": "crash-message_header_CnM8i",
	"error-message": "crash-message_error-message_2oNef",
	"errorMessage": "crash-message_error-message_2oNef"
};