exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* overridden by src/lib/themes/guiHelpers.js */\n\n.turbo-mode_turbo-container_1qQFO {\n    display: flex;\n    align-items: center;\n    padding: 0.25rem;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n        -ms-user-select: none;\n            user-select: none;\n}\n\n.turbo-mode_turbo-icon_A_vJA {\n    margin: 0.25rem;\n}\n\n.turbo-mode_turbo-label_1v23d {\n    font-size: 0.625rem;\n    font-weight: bold;\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    color: var(--control-primary);\n    white-space: nowrap;\n}\n", ""]);

// exports
exports.locals = {
	"turbo-container": "turbo-mode_turbo-container_1qQFO",
	"turboContainer": "turbo-mode_turbo-container_1qQFO",
	"turbo-icon": "turbo-mode_turbo-icon_A_vJA",
	"turboIcon": "turbo-mode_turbo-icon_A_vJA",
	"turbo-label": "turbo-mode_turbo-label_1v23d",
	"turboLabel": "turbo-mode_turbo-label_1v23d"
};