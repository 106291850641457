exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* overridden by src/lib/themes/guiHelpers.js */\n\n/* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */\n\n/* layout contants from `layout-constants.js` */\n\n.play-button_play-button_cXELI {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    overflow: hidden;  /* Mask the icon animation */\n    width: 2.5rem;\n    height: 2.5rem;\n    background-color: var(--sound-primary);\n    color: var(--ui-white);\n    border-radius: 50%;\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n        -ms-user-select: none;\n            user-select: none;\n    cursor: pointer;\n    transition: all 0.15s ease-out;\n}\n\n.play-button_play-button_cXELI {\n    position: absolute;\n    top: .5rem;\n    z-index: auto;\n}\n\n.play-button_play-button_cXELI:focus {\n    outline: none;\n}\n\n.play-button_play-icon_33XpJ {\n  width: 50%;\n}\n\n[dir=\"ltr\"] .play-button_play-button_cXELI {\n    right: .5rem;\n}\n\n[dir=\"rtl\"] .play-button_play-button_cXELI {\n    left: .5rem;\n}\n", ""]);

// exports
exports.locals = {
	"play-button": "play-button_play-button_cXELI",
	"playButton": "play-button_play-button_cXELI",
	"play-icon": "play-button_play-icon_33XpJ",
	"playIcon": "play-button_play-icon_33XpJ"
};