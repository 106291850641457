exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* overridden by src/lib/themes/guiHelpers.js */\n\n/* this class must contain \"see-inside-button\" somewhere for the s3 dev tools to be able to reinject itself */\n\n/* https://github.com/griffpatch/Scratch3-Dev-Tools/blob/0.2.4/inject3.js#L1804-L1807 */\n\n.tw-see-inside_see-inside-button_1fSk6 {\n    box-shadow: 0 0 0 1px var(--ui-black-transparent);\n}\n\n.tw-see-inside_see-inside-button-icon_UKAVj {\n    height: 1.25rem;\n}\n", ""]);

// exports
exports.locals = {
	"see-inside-button": "tw-see-inside_see-inside-button_1fSk6",
	"seeInsideButton": "tw-see-inside_see-inside-button_1fSk6",
	"see-inside-button-icon": "tw-see-inside_see-inside-button-icon_UKAVj",
	"seeInsideButtonIcon": "tw-see-inside_see-inside-button-icon_UKAVj"
};