exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */\n\n/* layout contants from `layout-constants.js` */\n\n/* overridden by src/lib/themes/guiHelpers.js */\n\n.asset-panel_wrapper_26zMp {\n    display: flex;\n    flex-grow: 1;\n    border: 1px solid var(--ui-black-transparent);\n    background: var(--assets-background);\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    font-size: 0.85rem;\n}\n\n[dir=\"ltr\"] .asset-panel_wrapper_26zMp {\n    border-top-right-radius: 0.5rem;\n    border-bottom-right-radius: 0.5rem;\n}\n\n[dir=\"rtl\"] .asset-panel_wrapper_26zMp {\n    border-top-left-radius: 0.5rem;\n    border-bottom-left-radius: 0.5rem;\n}\n\n.asset-panel_detail-area_3eEbw {\n    display: flex;\n    flex-grow: 1;\n    flex-shrink: 1;\n    overflow: visible;\n}\n\n[dir=\"ltr\"] .asset-panel_detail-area_3eEbw {\n    border-left: 1px solid var(--ui-black-transparent);\n}\n\n[dir=\"rtl\"] .asset-panel_detail-area_3eEbw {\n    border-right: 1px solid var(--ui-black-transparent);\n}\n", ""]);

// exports
exports.locals = {
	"wrapper": "asset-panel_wrapper_26zMp",
	"detail-area": "asset-panel_detail-area_3eEbw",
	"detailArea": "asset-panel_detail-area_3eEbw"
};